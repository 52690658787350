/* eslint-disable max-len */
import { RISK_SECTION_HIGH, RISK_SECTION_LOW, RISK_SECTION_MEDIUM } from '@consts/risk';
import {
  formatBMIQuestion,
  formatOption,
  formatQuestion,
  formatResult,
  formatRisk,
  formatYesNoQuestion,
} from '@/src/common/helpers/quiz';
import { COL_SIZE_FULL, QUESTION_TYPE_GENDER } from '@consts/quiz';
import { customAgeScores, customBmiScores } from '@/src/common/helpers/osteoporosis';

// HACK: Patients below 40 will always be low risk, keeping the low att -500 to be able to make room for redicing the score

export const riskSettings = [
  formatRisk(RISK_SECTION_LOW, 'common.low', 'risk.low', -500, 6),
  formatRisk(RISK_SECTION_MEDIUM, 'common.medium', 'risk.medium', 7, 12),
  formatRisk(RISK_SECTION_HIGH, 'common.high', 'risk.high', 13, 99999),
];

export const resultSettings = [
  formatResult(
    'You have, based on the information you provided, a low risk of having or developing osteoporosis. If you feel uncertain or believe that you are at risk for osteoporosis, we recommend that you consult with a healthcare provider for further evaluation.',
    '',
    -500,
    6,
  ),
  formatResult(
    'You have, based on the information you provided, a moderate risk of having or developing osteoporosis. We recommend that you contact your doctor for an individualized assessment of your condition. Your doctor will conduct testing to determine whether you have osteoporosis and implement any necessary measures to manage your situation optimally.',
    '',
    7,
    12,
  ),
  formatResult(
    'You have, based on the information you provided, a high risk of having or developing osteoporosis. We recommend that you contact your doctor for an individualized assessment of your condition. Your doctor will conduct testing to determine whether you have osteoporosis and implement any necessary measures to manage your situation optimally.',
    '',
    13,
    99999,
  ),
];

export const questions = [
  formatQuestion(
    'question1',
    'What sex was originally listed on your birth certificate?',
    [
      formatOption('Female', 2, 0, COL_SIZE_FULL, COL_SIZE_FULL, 'woman'),
      formatOption('Male', 0, 0, COL_SIZE_FULL, COL_SIZE_FULL, 'man'),
      formatOption('Decline to answer', 0, 0, COL_SIZE_FULL, COL_SIZE_FULL, 'other'),
    ],
    QUESTION_TYPE_GENDER,
  ),
  formatBMIQuestion(
    'question2',
    'bmi.title',
    customBmiScores,
    customAgeScores,
  ),
  formatYesNoQuestion(
    'question3',
    'Have you had one or more fractures (broken bones) as an adult?',
    4,
  ),
  formatYesNoQuestion(
    'question4',
    'Have either of you parents had a hip fracture (broken their hip)?',
    2,
  ),
  formatYesNoQuestion(
    'question5',
    'Do you smoke?',
    1,
  ),
  formatYesNoQuestion(
    'question6',
    'Are you receiving estrogen therapy?',
    2,
  ),
  formatYesNoQuestion(
    'question7',
    'Are you diagnosed with rheumatoid arthritis?',
    2,
  ),
];

export const references = [
  'FRAX indicates Fracture Risk Assessment Tool; ORAI, Osteoporosis Risk Assessment Instrument; OSIRIS, Osteoporosis Index of Risk; OST, Osteoporosis Self-assessment Tool; SCORE, Simple Calculated Osteoporosis Risk Estimation Tool.',
  '<a href="https://www.uspreventiveservicestaskforce.org/uspstf/recommendation/osteoporosis-screening#bootstrap-panel--10">https://www.uspreventiveservicestaskforce.org/uspstf/recommendation/osteoporosis-screening#bootstrap-panel--10</a>',
  '<a href="https://www.physio-pedia.com/The_Osteoporosis_Risk_Assessment_Instrument_(ORAI)">https://www.physio-pedia.com/The_Osteoporosis_Risk_Assessment_Instrument_(ORAI)</a>',
  '<a href="https://jamanetwork.com/journals/jama/fullarticle/2685995">https://jamanetwork.com/journals/jama/fullarticle/2685995</a>',
  '<a href="https://www.uspreventiveservicestaskforce.org/uspstf/recommendation/osteoporosis-screening">https://www.uspreventiveservicestaskforce.org/uspstf/recommendation/osteoporosis-screening</a>',
];
